import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = { class: "d-flex justify-content-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmationDialog = _resolveComponent("ConfirmationDialog")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmationDialog, {
      title: "Bạn có chắc chắn muốn xóa dữ liệu này?",
      width: "20%",
      "is-display": _ctx.isDeleteKhoConfirmationDialogDisplay,
      onOnConfirmButtonClick: _ctx.confirmDelete,
      onOnCloseButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDeleteKhoConfirmationDialogDisplay = false)),
      onOnCloseIconClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDeleteKhoConfirmationDialogDisplay = false))
    }, {
      default: _withCtx(() => [
        (_ctx.deletingKho)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.deletingKho.ten_kho), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-display", "onOnConfirmButtonClick"]),
    _createVNode(_component_el_table, {
      "max-height": "800",
      style: {"width":"100%"},
      size: "small",
      "cell-style": {padding: '0', height: '15px'},
      border: true,
      data: _ctx.items
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "ID",
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.id), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "Tên" }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.ten_kho), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "Mã",
          width: "60"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.ma_kho), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "Địa chỉ",
          width: "300"
        }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.dia_chi), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "Ghi chú" }, {
          default: _withCtx((scope) => [
            _createTextVNode(_toDisplayString(scope.row.ghi_chu), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          width: "150",
          fixed: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ButtonCustom, {
                "button-type": _ctx.ButtonsType.INFO,
                "color-type": _ctx.ButtonTypeColors.INFO,
                onClick: ($event: any) => (_ctx.push(`/quan-ly/kho/chi-tiet/${scope.row.id}`))
              }, null, 8, ["button-type", "color-type", "onClick"]),
              _createVNode(_component_ButtonCustom, {
                "button-type": _ctx.ButtonsType.EDIT,
                "color-type": _ctx.ButtonTypeColors.SUCCESS,
                onClick: ($event: any) => (_ctx.push(`/quan-ly/kho/${scope.row.id}/cap-nhat`))
              }, null, 8, ["button-type", "color-type", "onClick"]),
              _createVNode(_component_ButtonCustom, {
                hidden: "",
                "button-type": _ctx.ButtonsType.TRASH,
                "color-type": _ctx.ButtonTypeColors.DANGER,
                onClick: ($event: any) => (_ctx.onDeleteKhoClicked(scope.row.id))
              }, null, 8, ["button-type", "color-type", "onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}