
import { defineComponent, PropType } from 'vue';
import { IKhoResponseBase } from '@/core/interfaces/ApiResponses';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import {getErrorMsg, swalNotification} from '@/core/helpers/utils';
import { KhoService } from '@/core/services/Kho.service';
import { useButtonCustom } from '../../../../../core/hooks/use-button-custom';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';

export default defineComponent({
	name: 'danh-sach-kho-table',

	components: { ButtonCustom, ConfirmationDialog },

	emits: ['on-delete-kho'],

	setup() {
		const { push } = useRouterCustom();
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

		return {
			push,
			ButtonsType, ButtonTypeColors,
		};
	},

	data: () => ({
		isDeleteKhoConfirmationDialogDisplay: false,
		deletingKho: null as IKhoResponseBase | null,
	}),

	props: {
		items: Array as PropType<Array<IKhoResponseBase>>
	},

	methods: {
		onDeleteKhoClicked(id) {
			const kho = this.items?.find(item => item.id === id);
			this.isDeleteKhoConfirmationDialogDisplay = true;
			if (kho) {
				this.deletingKho = kho;
			}
		},

		async confirmDelete() {
			this.isDeleteKhoConfirmationDialogDisplay = false;

			try {
				if (this.deletingKho) {
					await KhoService.delete(this.deletingKho.id);
				}
				await swalNotification(
					'Xóa thành công',
				);
			} catch (e) {
				await swalNotification(
					getErrorMsg(e, 'Có lỗi xảy ra khi xóa kho này'),
					'error'
				)
			}
		}
	}
})
