
import { ref, defineComponent, onMounted } from "vue";
import { KhoService } from "@/core/services/Kho.service";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getErrorMsg, swalNotification } from '@/core/helpers/utils';
import { useRouterCustom } from "@/core/hooks/use-router-custom";
import { IKhoResponseBase } from '@/core/interfaces/ApiResponses';
import { useLoading } from "@/core/hooks/use-loading";

import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DanhSachKhoTable from '@/views/crafted/pages/kho/danh-sach-kho-table/DanhSachKhoTable.vue';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

export default defineComponent({
  name: "warehouses",

	components: { DanhSachKhoTable, PageRowLayout, ButtonCustom },

	setup() {
		const { push } = useRouterCustom();
		const { startLoading, setLoading, endLoading } = useLoading();
		const { ButtonsType } = useButtonCustom();
    const store = useStore();
    const khoItems = ref<IKhoResponseBase[]>([]);

		const fetchData = async () => {
			startLoading();
			try {
				const {
					data: {
						data: { data: khoItemsResponse },
					},
				} = await KhoService.fetchAllWarehousesByDepartmentId(
					store.getters.currentUser.departmentId
				);
				khoItems.value = khoItemsResponse;
				endLoading();
			} catch (err) {
				endLoading();
				await swalNotification(
					getErrorMsg(err, 'Có lỗi xảy ra khi lấy danh sách kho'),
					'error',
				);
			}
		}

    onMounted(async () => {
      setCurrentPageTitle("Quản lý kho");
			await fetchData();
    });

    return {
			ButtonsType,
			khoItems,
			push,
			startLoading, setLoading, endLoading,
			fetchData,
    };
  },
});
