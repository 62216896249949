import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonCustom = _resolveComponent("ButtonCustom")!
  const _component_DanhSachKhoTable = _resolveComponent("DanhSachKhoTable")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_openBlock(), _createBlock(_component_PageRowLayout, null, {
    "page-row-header": _withCtx(() => [
      _createTextVNode(" Danh sách ")
    ]),
    "page-row-action-button": _withCtx(() => [
      _createVNode(_component_ButtonCustom, {
        "button-type": _ctx.ButtonsType.ADD,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.push('/quan-ly/kho/tao-moi')))
      }, null, 8, ["button-type"])
    ]),
    "page-row-content": _withCtx(() => [
      _createVNode(_component_DanhSachKhoTable, {
        items: _ctx.khoItems,
        onOnDeleteKho: _ctx.fetchData
      }, null, 8, ["items", "onOnDeleteKho"])
    ]),
    _: 1
  }))
}